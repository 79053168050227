import { graphql } from "gatsby"
import React from "react"
import OurWorkPage from '../../components/pages/OurWork'

const lang = 'es'

const OurWork = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_es,
    titleSeo: seoDataJson.title_es,
    description: seoDataJson.description_es,
    alternateLanguage: seoDataJson.alternateLanguage_es,
    alternateUrl: seoDataJson.alternateUrl_es,
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "OUR-WORK"
  })

  return (
    <OurWorkPage
      lang={lang}
      data={data}
      seoData={seoData}
      urls={resultUrls}
    />
  )
}

export const imagesOurWork = graphql`
  query imagesOurWorkEs {
    allFile(filter: { ext: { regex: "/jpg|png/" } }) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        extension
        publicURL
      }
    }
    logos: allFile(filter: { extension: { eq: "svg" } }) {
      nodes {
        extension
        publicURL
      }
    }
    allProjectsJson(filter: { lang: { eq: "es" } }) {
      edges {
        node {
          name
          fileName
          description
          sideChange
          logo
          lang
          sendTo
        }
      }
    }
    seoDataJson(name: { eq: "our-work" }) {
      url_es
      title_es
      description_es
      alternateLanguage_es
      alternateUrl_es
    }
  }
`

export default OurWork
